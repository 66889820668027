<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom:1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
    />
    <!-- 编辑器 -->
    <Editor
      style="height:400px; overflow-y:hidden"
      :defaultConfig="editorConfig"
      v-model="html"
      @onChange="onChange"
      @onCreated="onCreated"
      @onInsertedImage="InsertImage"
    />
  </div>
</template>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<script>
import "@wangeditor/editor/dist/css/style.css";
import attachmentModule from "@wangeditor/plugin-upload-attachment";
Boot.registerModule(attachmentModule);
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { Boot } from "@wangeditor/editor";
import axios from "axios";
export default {
  name: "EtaxWebV2WangEditor",
  components: { Editor, Toolbar },

  props: {
    html: {
      default: "",
    },
  },
  data() {
    return {
      editor: null,
      toolbarConfig: {
        insertKeys: {
          index: 22,
          keys: ["uploadAttachment"],
        },
      },
      editorConfig: {
        MENU_CONF: {
          uploadAttachment: {
            title: "上传附件",
            fieldName: "file",
            timeout: 100 * 1000,
            customUpload: this.update,
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },
  created() {
    this.editorConfig.MENU_CONF["uploadImage"] = {
      customInsert(res, insertFn) {
        // JS 语法
        // res 即服务端的返回结果
        // 从 res 中找到 url alt href ，然后插入图片
        insertFn("https://file.listensoft.net" + res.data.url, "xx", "xx");
      },
      fieldName: "upFile",
      server: "https://public.listensoft.net/api/uploadImg",
      maxFileSize: 10 * 1024 * 1024,
    };
    this.editorConfig.MENU_CONF['uploadVideo'] = {
      customUpload: async (file, insertFn) => {
        let resultUrl = await this.uploadVideoCS(file, file.name);
        insertFn(resultUrl);
      },
    }
   
  },
  mounted() {},
  beforeUnmount(){
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onChange() {
      this.$emit("update:html", this.html);
      // //console.log(this.html.indexOf('<img'));
      // //console.log(this.insertStr(this.html,7,''+' style="width:200px;height:200px"'));
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // this.editor.setHtml(this.insertStr(this.html,7,''+' style="width:200px;height:200px"'));
    },
    // insertStr(source, start, newStr) {
    //   return source.slice(0, start) + newStr + source.slice(start)
    // }
    update(file, insertFn) {
      const param = new FormData(); // 创建form对象
      param.append("upFile", file, file.name);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("https://public.listensoft.net/api/uploadImg", param, config)
        .then((res) => {
          this.$qzfMessage("上传成功");
          insertFn( file.name,"https://file.listensoft.net" + res.data.data.url)
        })
    },
    uploadVideoCS(file,name){
      return new Promise((resolve) => {
        const param = new FormData(); // 创建form对象
        param.append("upFile", file, name);
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        axios
          .post("https://public.listensoft.net/api/uploadImg", param, config)
          .then((res) => {
            this.$qzfMessage("上传成功");
            let resultUrl = "https://file.listensoft.net" + res.data.data.url;
            resolve(resultUrl)
          })
      })
    }
  },
};
</script>
